<template>
	<div class="w-6 h-6">
		<component
			:is="activeIcon"
			:class="[
				iconClasses,
				'w-6 h-6'
			]"
		/>
	</div>
</template>

<script async>
import {
	BLOG,
	BRANDS,
	CBD_STORES,
	DEALS,
	DELIVERY,
	DISPENSARY,
	MEDICAL,
	PRODUCTS,
	STRAINS
} from '@/constants/navigation/nav-items.js'

export default {
	props: {
		icon: {
			type: String,
			required: true
		},
		iconClasses: {
			type: String,
			default: ''
		}
	},
	computed: {
		activeIcon() {
			switch (this.icon) {
				case BLOG:
					return () => import('@/components/icons/BlogIcon.vue')
				case BRANDS:
					return () => import('@/components/icons/BrandsIcon.vue')
				case CBD_STORES:
					return () => import('@/components/icons/CbdStoresIcon.vue')
				case DELIVERY:
					return () => import('@/components/icons/DeliveryIcon.vue')
				case DEALS:
					return () => import('@/components/icons/DealsIcon.vue')
				case DISPENSARY:
					return () => import('@/components/icons/DispensaryIcon.vue')
				case MEDICAL:
					return () => import('@/components/icons/MedicalIcon.vue')
				case PRODUCTS:
					return () => import('@/components/icons/ProductsIcon.vue')
				case STRAINS:
					return () => import('@/components/icons/StrainsIcon.vue')

				default:
					return ''
			}
		}
	}
}
</script>
