<template>
	<div
		ref="contentWrapper"
		:style="{ backgroundImage: `url(${heroBackgroundImg})` }"
		class="relative flex items-center text-left text-white transition-all duration-300 ease-in-out bg-center bg-cover md:h-auto h-80 min-h-74 md:min-h-64"
	>
		<div class="w-full h-full max-w-6xl py-4 m-auto overflow-hidden">
			<div class="flex flex-col h-full m-auto md:py-2 md:content-evenly">
				<div
					ref="h1"
					class="flex items-center w-full md:block md:min-h-9"
				>
					<h1
						v-if="heroH1.length"
						class="w-full px-4 text-3xl font-bold xs:text-2xl"
					>
						{{ heroH1 }}
					</h1>
				</div>
				<h2
					ref="h2"
					class="w-full px-4 pt-2 mb-6 text-lg xs:mb-2 xs:text-base"
				>
					Search hundreds of cannabis businesses in your community
				</h2>
				<PillNav
					ref="pillNavList"
					:nav-items="navItems"
					:expanded="expanded"
					class="pb-2 md:pb-0"
				/>
				<div
					v-show="isExpandable"
					ref="arrowButton"
					class="absolute flex w-full py-2 transform -translate-x-1/2 -translate-y-1/2 left-1/2 -bottom-6 md:hidden"
				>
					<button
						class="w-20 pb-1 mx-auto rounded-lg outline-none focus:ring ring-purple-500"
						@click="toggleAccordion"
					>
						<div
							id="button-text"
							class="mb-1 text-center"
						>
							{{ accordionButtonText }}
						</div>
						<ArrowDown
							:class="[
								'mx-auto text-white',
								'transition-all ease-in-out delay-100',
								{ 'transform rotate-180': expanded }
							]"
						/>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import ArrowDown from '@/components/icons/ArrowDown.vue'
import PillNav from '@/components/navigation/pill-nav/PillNav.vue'

const PILL_HEIGHT = 58

export default {
	components: {
		PillNav,
		ArrowDown
	},
	props: {
		heroH1: {
			type: String,
			default: ''
		},
		navItems: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			heroBackgroundImg: require('@/assets/backgrounds/leaf-header-background.jpg'),
			expanded: false,
			isMounted: false,
			PILL_HEIGHT
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		metaPreload() {
			return { rel: 'preload', href: this.heroBackgroundImg }
		},
		pills() {
			return this.navItems
		},
		hasPillNav() {
			return this.pills && !!this.pills.length
		},
		isExpandable() {
			return this.hasPillNav && this.pills.length > 2
		},
		mobilePillRows() {
			return Math.ceil(this.pills.length / 2)
		},
		showArrowButton() {
			return this.hasPillNav && this.mobilePillRows > 1
		},
		accordionButtonText() {
			return this.expanded ? 'Close' : 'View All'
		},
		expandedPillSectionHeight() {
			return PILL_HEIGHT * this.mobilePillRows
		}
	},
	watch: {
		'$route.params.city': {
			handler: function() {
				if (this.isMounted) {
					this.expanded = false
				}
			},
			deep: true
		},
		isMounted() {
			this.setHeroCollapsedHeight()
		}
	},
	mounted() {
		this.isMounted = true
		window.addEventListener('resize', this.resizeHandler)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeHandler)
	},
	updated() {
		this.setHeroHeightAfterNewRoute()
	},
	methods: {
		...mapMutations([ 'pushMetaLink' ]),
		resizeHandler() {
			this.isMounted && this.isMobile ? this.setResizedHeight() : this.removeResizedHeightForDesktop()
		},
		setResizedHeight() {
			this.expanded ? this.setHeroExpandedHeight() : this.setHeroCollapsedHeight()
		},
		removeResizedHeightForDesktop() {
			const contentWrapper = this.$refs?.contentWrapper
			if (contentWrapper.style.height.length) {
				this.expanded = false
				contentWrapper.style.removeProperty('height')
			}
		},
		getArrowHeight() {
			return this.$refs?.arrowButton?.clientHeight
		},
		getH1Height() {
			return this.heroH1.length ? this.$refs?.h1?.clientHeight : 0
		},
		getH2Height() {
			return this.$refs?.h2?.clientHeight
		},
		calculateExpandedHeroHeight() {
			const extraPadding = 30
			const expandedHeroHeight = this.getH1Height() + this.getH2Height() + this.expandedPillSectionHeight + this.getArrowHeight() + extraPadding
			return `${expandedHeroHeight.toString()}px`
		},
		calculateCollapsedHeroHeight() {
			const extraPadding = 36
			const collapsedHeroHeight = this.getH1Height() + this.getH2Height() + PILL_HEIGHT + this.getArrowHeight() + extraPadding
			return `${collapsedHeroHeight.toString()}px`
		},
		setHeroCollapsedHeight() {
			if (this.isMobile) {
				const contentWrapper = this.$refs?.contentWrapper
				contentWrapper.style.height = this.calculateCollapsedHeroHeight()
			}
		},
		setHeroExpandedHeight() {
			if (this.isMobile) {
				const contentWrapper = this.$refs?.contentWrapper
				contentWrapper.style.height = this.calculateExpandedHeroHeight()
			}
		},
		setHeroHeightAfterNewRoute() {
			if (!this.expanded && this.isMobile) this.setHeroCollapsedHeight()
		},
		toggleAccordion() {
			this.expanded ? this.setHeroCollapsedHeight() : this.setHeroExpandedHeight()
			this.expanded = !this.expanded
		}
	}
}
</script>
