<template>
	<div class="w-full pb-4 mx-auto bg-white">
		<div v-if="!hasLocation && !loading">
			<div class="md:h-auto h-80 min-h-74 md:min-h-64">
				<div
					:style="{ backgroundImage: `url(${heroBackgroundImg})` }"
					class="px-8 py-20 text-white bg-center bg-cover md:py-40 lg:py-72"
				>
					<h1 class="mb-4">
						Discover cannabis culture in your community
					</h1>
					<div
						class="max-w-lg py-2 m-auto text-xl font-bold text-gray-500 bg-white rounded shadow pointer-cursor"
						@click="focusGlobalSearch"
					>
						Where's your weed?
					</div>
				</div>
			</div>
			<div class="max-w-6xl m-auto">
				<h2
					class="mt-4"
					style="width: 100%;"
				>
					Recent Articles
				</h2>
				<CityCarouselRow
					v-if="showBlogArticles"
					class="w-full mb-2"
					:items="blogs"
					:list-type="BLOGS_LIST"
					:item-count="blogs.length"
					heading="Latest Blog Articles"
					url="/blog"
					bg-color="bg-white"
					fade-color-from="from-white"
				/>
			</div>
		</div>
		<div v-else>
			<CityHero
				:hero-h1="heroH1"
				:nav-items="geoSections"
			/>
			<div class="grid w-full max-w-6xl grid-cols-1 m-auto">
				<div class="px-3 py-4 text-left md:py-5 min-h-[84px] md:min-h-[68px]">
					<div
						v-if="loading"
						class="flex items-center justify-center w-full h-full text-center"
					>
						<div class="mr-4 text-gray-300">
							Loading...
						</div>
						<LoadingSpinner class="text-gray-300 w-7 h-7" />
					</div>
					<WwReadMore
						v-if="!loading && introText"
						@is-expanded="setExpanded"
					>
						<WwHtmlContent
							class="text-left"
							:markup="introText"
							:expanded="expanded"
							use-line-clamp
						/>
					</WwReadMore>
					<template v-if="!loading && !introText">
						<div class="flex h-full">
							<button
								class="font-bold text-left text-black transition-all duration-200 ease-in-out bg-white rounded-md xl:hover:underline focus:outline-none focus:ring focus:ring-offset-2 focus:ring-green-500 focus:ring-opacity-80"
								@click="showModal(NEWSLETTER_SIGNUP_MODAL)"
							>
								Join our <span class="text-green-500 xl:hover:underline">
									newsletter
								</span> to get the latest news!
							</button>
						</div>
					</template>
				</div>
				<CityCarouselRow
					v-if="showDeliveryBusinesses"
					class="w-full mb-2 h-96"
					:items="deliveryBusinesses"
					:list-type="BUSINESSES_LIST"
					:item-count="deliveryBusinesses.length"
					:heading="`Delivery Services in ${cityName}`"
					:url="cityUrl + '/marijuana-delivery'"
					:loading="loading"
				/>
				<CityCarouselRow
					v-if="showDispensaryBusinesses"
					class="w-full mb-2"
					:items="dispensaryBusinesses"
					:list-type="BUSINESSES_LIST"
					:item-count="dispensaryBusinesses.length"
					:heading="`Dispensaries in ${cityName}`"
					:url="cityUrl + '/marijuana-dispensaries'"
					:loading="loading"
				/>
				<div class="flex justify-center w-full pt-4">
					<WwAdvertisement
						:zone="11"
						:number-of-ads-on-page="numberOfAdsOnPage"
						:ad-index="0"
						class="px-3"
					/>
					<WwAdvertisement
						:zone="11"
						:number-of-ads-on-page="numberOfAdsOnPage"
						:ad-index="2"
						class="hidden px-3 lg:flex"
					/>
				</div>
				<CityCarouselRow
					v-if="showCbdBusinesses"
					:class="[
						'w-full mb-2',
						{ 'order-2': !deliveryBusinesses.length && !dispensaryBusinesses.length }
					]"
					:items="cbdBusinesses"
					:list-type="BUSINESSES_LIST"
					:item-count="cbdBusinesses.length"
					:heading="`CBD Stores in ${cityName}`"
					:url="cityUrl + '/cbd-stores'"
					:loading="loading"
				/>
				<CityCarouselRow
					v-if="showBlogArticles"
					class="w-full mb-2"
					:items="blogs"
					:list-type="BLOGS_LIST"
					:item-count="blogs.length"
					heading="Latest Blog Articles"
					url="/blog"
					bg-color="bg-white"
					fade-color-from="from-white"
					:loading="loading"
				/>
				<div class="p-3">
					<WwFaq
						v-if="hasFAQs"
						id="faqs"
						:faq="faqs"
						:heading="heading"
						:columns="2"
						:content-classes="[{'xl:grid-cols-2': hasCityGuide}, 'grid grid-cols-1 gap-4']"
					>
						<template
							v-if="hasStateLaws"
							#button
						>
							<div class="group">
								<router-link
									tag="button"
									:to="stateUrl"
									class="w-full p-3 bg-gray-200 rounded shadow-sm cursor-pointer group-hover:shadow"
								>
									<div class="flex font-bold text-left flex-nowrap">
										<div class="pr-4">
											<div class="text-sm">
												What are the current Cannabis laws in {{ cityName }}?
											</div>
											<div class="text-xs text-green-500 group-hover:text-purple-500">
												View your local laws
											</div>
										</div>
										<div class="ml-auto text-4xl font-bold text-gray-500">
											<ArrowRight class="h-6" />
										</div>
									</div>
								</router-link>
							</div>
						</template>
						<template #after>
							<TravelGuide
								v-if="hasCityGuide"
								:city-url="`${cityUrl}`"
								:city-name="cityName"
								:state-name="stateName"
								class="order-first mt-4 xl:order-last"
							/>
						</template>
					</WwFaq>
				</div>
				<div class="flex justify-center w-full pt-4 pb-2 md:max-w-lg md:m-auto">
					<WwAdvertisement
						:zone="11"
						:number-of-ads-on-page="numberOfAdsOnPage"
						:ad-index="1"
						class="px-3"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import ArrowRight from '@/components/icons/ArrowRight.vue'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import WwFaq from '@/components/UI/faq/WwFaq.vue'
import WwAdvertisement from '@/components/UI/WwAdvertisement.vue'
import WwHtmlContent from '@/components/UI/WwHtmlContent.vue'
import WwReadMore from '@/components/UI/WwReadMore.vue'
import { NEWSLETTER_SIGNUP_MODAL } from '@/constants/modal/names.js'
import { GLOBAL_SEARCH_ID } from '@/constants/search/inputIds.js'
import { BLOGS_LIST, BUSINESSES_LIST } from '@/constants/search/listTypes.js'
import { GetCityIndexPageData } from '@/gql/city/queries/pages.gql'
import { pageLevelGQLErrors } from '@/utils/error-handling.js'
import CityCarouselRow from '@/views/city/components/carousel-rows/CityCarouselRow.vue'
import CityHero from '@/views/city/components/CityHero.vue'
import TravelGuide from '@/views/city/components/TravelGuide.vue'

export default {
	components: {
		CityHero,
		TravelGuide,
		WwReadMore,
		WwHtmlContent,
		WwFaq,
		CityCarouselRow,
		WwAdvertisement,
		ArrowRight,
		LoadingSpinner
	},
	data() {
		return {
			BUSINESSES_LIST,
			NEWSLETTER_SIGNUP_MODAL,
			BLOGS_LIST,
			expanded: false,
			heroBackgroundImg: require('@/assets/wheresweed-home-hero.jpg'),
			loading: 0
		}
	},
	apollo: {
		cityPageData: {
			query: GetCityIndexPageData,
			update(data) {
				if (data.meta) this.$store.dispatch('setMeta', data.meta)
				if (data?.meta?.location) this.$store.dispatch('setLocation', data.meta?.location)
				if (data.cities?.[0]) {
					const cityData = data.cities[0]
					return {
						...data.cities[0],
						blogArticles: data?.blogs || [],
						cbdBusinesses: cityData?.cbdBusinesses || [],
						dispensaryBusinesses: cityData?.dispensaryBusinesses || [],
						deliveryBusinesses: cityData?.deliveryBusinesses || []
					}
				}
				return []
			},
			variables() {
				return {
					seoUrl: this.$route.params.city,
					state: this.$route.params.state
				}
			},
			error(error) {
				pageLevelGQLErrors(error, this.$store)
			},
			errorPolicy: 'none'
		}
	},
	computed: {
		...mapGetters([
			'geoSections'
		]),
		hasLocation() {
			return !!this.cityUrl
		},
		hasStateLaws() {
			return !!this.cityPageData?.state?.hasLaws
		},
		cityUrl() {
			if (this.$route?.params?.city) {
				return this.$route.path
			}
			return this.cityPageData?.url || ''
		},
		cityName() {
			if (this.cityPageData?.name && this.cityPageData.name !== 'Washington DC') {
				return `${this.cityPageData.name}, ${this.cityPageData?.state?.short}`
			}
			return this.cityPageData?.name || ''
		},
		stateName() {
			return this.cityPageData?.state?.name || ''
		},
		stateUrl() {
			return this.cityPageData?.state?.url || ''
		},
		cbdBusinesses() {
			return this.cityPageData?.cbdBusinesses || []
		},
		deliveryBusinesses() {
			return this.cityPageData?.deliveryBusinesses || []
		},
		dispensaryBusinesses() {
			return this.cityPageData?.dispensaryBusinesses || []
		},
		blogs() {
			return this.cityPageData?.blogArticles || []
		},
		showCbdBusinesses() {
			return !!this.cbdBusinesses?.length || this.loading
		},
		showDeliveryBusinesses() {
			return !!this.deliveryBusinesses?.length || this.loading
		},
		showDispensaryBusinesses() {
			return !!this.dispensaryBusinesses?.length || this.loading
		},
		showBlogArticles() {
			return !!this.blogs.length || this.loading
		},
		heroH1() {
			if (this.cityName && !!this.cityName.length) {
				return `Discover cannabis culture in ${this.cityName}`
			}
			return 'Discover cannabis culture in your community'
		},
		introText() {
			return this.cityPageData?.description
		},
		faqs() {
			return this.cityPageData?.faqs
		},
		hasCityGuide() {
			return this.cityPageData?.hasCityGuide
		},
		hasFAQs() {
			return !!this.faqs?.length
		},
		heading() {
			return 'Frequently asked questions about cannabis in ' + this.cityName
		},
		numberOfAdsOnPage() {
			return this.cityHasOrdering ? 2 : 3
		},
		cityHasOrdering() {
			return false
			// not currently supported by api
		}
	},
	watch: {
		introText: {
			handler: function () {
				this.readMore = this.introText && this.introText.length < 1500
			},
			immediate: true
		}
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		setExpanded(isExpanded) {
			this.expanded = isExpanded
		},
		focusGlobalSearch() {
			try {
				if (document.getElementById(GLOBAL_SEARCH_ID)) {
					document.getElementById(GLOBAL_SEARCH_ID).focus()
				}
			} catch (e) {
				pageLevelGQLErrors(e)
			}
		}
	}
}
</script>
