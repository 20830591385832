<template>
	<div
		:class="[
			'flex flex-col text-center items-start justify-between relative',
			isExpanded ? 'pb-4 md:pb-0' : 'pb-0',
			triggerPlacement
		]"
	>
		<div
			ref="scrollTarget"
			class="absolute top-0 -mt-28 md:-mt-20"
		/>
		<div
			ref="wrapperEl"
			class="w-full animate-wrapper-height"
			:class="isExpanded ? 'max-height': 'line-clamp-1'"
			:style="`max-height: ${ isExpanded ? FULL_HEIGHT : collapsedHeight }`"
		>
			<div
				ref="contentEl"
				class="prose prose-lg -mt-[27px] max-w-none"
			>
				<slot :hide-trigger="handleHideTrigger" />
			</div>
		</div>
		<div class="flex">
			<slot name="footer" />
			<button
				v-if="showTrigger || isExpanded"
				class="font-bold text-left text-green-500 whitespace-nowrap"
				@click="handleTriggerClick"
			>
				<slot name="trigger">
					{{ triggerText }}
				</slot>
			</button>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

const FULL_HEIGHT = '100%'

export default {
	props: {
		maxHeight: {
			type: Number,
			default: 30
		},
		keepTriggerOnBottom: {
			type: Boolean,
			default: false
		}

	},
	emits: [ 'is-expanded' ],
	data() {
		return {
			isExpanded: false,
			wrapperElHeight: 0,
			contentElHeight: 0,
			showTrigger: true,
			FULL_HEIGHT
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		isExpandable() {
			return this.maxHeight < this.contentElHeight
		},
		collapsedHeight() {
			return `${this.maxHeight}px`
		},
		triggerText() {
			return this.isExpanded ? 'View Less' : 'Read More'
		},
		triggerPlacement() {
			if (this.keepTriggerOnBottom) return 'flex-col space-y-2'
			return 'md:flex-row'
		}
	},
	watch: {
		isExpanded() {
			this.$emit('is-expanded', this.isExpanded)
		}
	},
	async mounted() {
		await this.$nextTick()
		if (!this.$refs.wrapperEl) { return }
		this.wrapperElHeight = this.$refs.wrapperEl?.clientHeight
		this.contentElHeight = this.$refs.contentEl?.clientHeight
	},
	methods: {
		async handleTriggerClick() {
			this.isExpanded = !this.isExpanded
			if (!this.isExpanded && this.isMobile) {
				this.$nextTick(() => {
					this.$refs.scrollTarget.scrollIntoView({ behavior: 'smooth' })
				})
			}
		},
		handleHideTrigger() {
			this.showTrigger = false
		}
	}
}
</script>

<style scoped>
.read-more-button-position {
	bottom: -0.75rem;
}
.fade-to-white-y {
	background: rgb(255, 255, 255);
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(251, 254, 255, 1) 80%);
}
.max-height {
	max-height: 3000px !important;
}
.animate-wrapper-height {
	transition: max-height 0.25s ease-in-out;
}
</style>
