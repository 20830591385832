<template>
	<div v-track-event:custom.click="customTracker">
		<router-link
			:to="link"
			class="flex items-center w-40 px-4 text-black transition-all ease-in-out bg-white rounded-full shadow-lg outline-none xs:px-2 focus:ring ring-purple-500 hover:shadow-xl xs:w-34 h-9"
		>
			<NavIcon
				:icon="label"
				class="mr-2 text-purple-500 shrink-0 xs:mr-1"
				icon-classes="ml-0"
			/>
			<div class="xs:text-base whitespace-nowrap">
				{{ label }}
			</div>
		</router-link>
	</div>
</template>

<script async>
import NavIcon from '@/components/navigation/NavIcon.vue'
import { PILL_NAV_CUSTOM_MAP } from '@/constants/logging/customEvents.js'

export default {
	components: {
		NavIcon
	},
	props: {
		label: {
			type: String,
			required: true
		},
		link: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		}
	},
	computed: {
		customTracker() {
			return {
				customMap: PILL_NAV_CUSTOM_MAP,
				event: {
					name: 'pillNav',
					params: {
						paramName: 'pillName',
						paramValue: this.name
					}
				}
			}
		}
	}
}
</script>
