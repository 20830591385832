<template>
	<div class="min-h-16">
		<TransitionGroup
			v-if="navItems.length && isMounted"
			name="fade-in"
			:appear="!showArrowButton"
			mode="out-in"
			tag="ul"
			class="grid justify-center w-full grid-cols-2 px-4 gap-x-4 md:flex md:flex-wrap md:justify-start"
		>
			<li
				v-for="(pill, index) in navItems"
				v-show="showPill(index)"
				ref="pillNavItem"
				:key="pill.key"
				:style="{'--index': index}"
				class="mx-auto my-2 md:mx-0"
			>
				<PillNavItem
					:label="pill.name"
					:link="pill.url"
					:name="pill.key"
				/>
			</li>
		</TransitionGroup>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import PillNavItem from '@/components/navigation/pill-nav/PillNavItem.vue'

export default {
	components: {
		PillNavItem
	},
	props: {
		navItems: {
			type: Array,
			required: true
		},
		expanded: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isMounted: false
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		showArrowButton() {
			return this.isMobile
		},
		hideExtraPills() {
			return !this.expanded && this.showArrowButton
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		showPill(index) {
			return this.hideExtraPills ? index < 2 : true
		}
	}
}
</script>

<style scoped>
@media (min-width: 1280px) {
	.fade-in-move,
	.fade-in-enter-active,
	.fade-in-leave-active {
		transition: all .5s ease;
		transition-delay: calc(0.08s * var(--index));
	}
	.fade-in-enter,
	.fade-in-leave-to {
		opacity: 0;
		transform: translateY(50px);
		transition: all 0.2s ease;
	}
}

.fade-in-enter-active,
.fade-in-leave-active {
	transition: all 0.15s ease;
	transition-delay: calc(0.08s * var(--index));
}
.fade-in-enter,
.fade-in-leave-to {
	opacity: 0;
	transform: translateY(50px);
	transition: all 0.2s ease;
}
</style>
