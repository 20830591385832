<template>
	<div
		ref="travelGuideBanner"
		class="relative w-full h-auto min-h-60 md:min-h-0 bg-green-500 rounded-xl group hover:shadow-md"
	>
		<router-link
			:to="cityUrl + '/travel-guide'"
			class="grid content-between w-full h-auto grid-cols-1 gap-6 p-6 my-auto text-left md:px-6 md:py-4 md:grid-cols-3 md:content-center"
		>
			<div
				ref="textContent"
				class="md:col-span-2"
			>
				<h2 class="pb-2 md:pb-0 text-xl text-left font-bold text-white uppercase line-clamp-2">
					{{ cityName }} Travel Guide
				</h2>
				<p class="pt-2 text-base text-left text-white md:text-lg">
					Where to buy weed in {{ stateName }}? That is a question a lot of people planning their vacations want to know. We have the answers.
				</p>
			</div>
			<button class="w-full h-12 px-2 text-sm font-bold text-white uppercase bg-green-500 border-2 border-white rounded-lg group-hover:bg-purple-500 md:self-center">
				Map Your Buying
			</button>
		</router-link>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

export default {
	props: {
		cityName: {
			type: String,
			required: true
		},
		stateName: {
			type: String,
			required: true
		},
		cityUrl: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isMounted: false
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ])
	},
	watch: {
		isMounted() {
			this.setHeight()
		},
		isMobile() {
			this.setHeight()
		}
	},
	mounted() {
		this.isMounted = true
		window.addEventListener('resize', this.resizeHandler)
		this.setHeight()
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeHandler)
	},
	updated() {
		this.setHeight()
	},
	methods: {
		resizeHandler() {
			this.setHeight()
		},
		setHeight() {
			if (!this.isMobile) {
				this.$refs.travelGuideBanner.style.height = this.$refs.textContent.clientHeight + 32 + 'px'
			} else {
				this.$refs.travelGuideBanner.style.removeProperty('height')
			}
		}
	}
}
</script>
