<template>
	<DefaultLayout :full-width-header="fullWidthHeader">
		<router-view
			id="city-shell"
		/>
		<!-- slot to mobile map controls -->
		<template
			v-if="isMobileListingPage"
			#mobile-map-controls
		>
			<map-controls />
		</template>
	</DefaultLayout>
</template>

<script async>
import { mapActions, mapGetters } from 'vuex'

import DefaultLayout from '@/layouts/default/Index.vue'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		DefaultLayout,
		MapControls: () => import('@/components/map/MapControls.vue')
	},
	data () {
		return {
			isMounted: false,
			pageNumber: 0
		}
	},
	computed: {
		...mapGetters([ 'ssrPageLoaded', 'isMobile' ]),
		fullWidthHeader() {
			return !!this.$route?.meta.fullWidthHeader
		},
		isMobileListingPage() {
			return this.fullWidthHeader && this.isMobile
		}
	},
	watch: {
		'$route' (to, from) {
			this.fetchData({ to, from })
		}
	},
	mounted: function () {
		this.isMounted = true
		this.fetchData({ to: this.$route })
	},
	methods: {
		...mapActions('city', [ 'SET', 'NEXT_PAGE' ]),
		...mapActions([ 'newGet', 'setSsrPageLoad' ]),
		async fetchData({ to, from }) {
			if (to.params.city === 'null' && to.name !== 'home') {
				this.$store.commit('setError', { status: 301, redirect: '/' })
				return
			}

			if (to && this.ssrPageLoaded === to.fullPath && this.isMounted) {
				this.$store.commit('setSsrPageLoad', true)
				return
			}

			let endpoint = 'cities/'
			const params = {}
			const filters = this.scrubFilters({ to })

			if (filters) {
				params.filters = filters
			}

			if (to.name === 'cityDeals' || to.name === 'cityDealsWithFilters') {
				endpoint += 'deals/'
				params.page = this.pageNumber
			} else if (to.name === 'home') {
				endpoint = 'City/getHomePageData'
			} else if (to.name !== 'cityIndex') {
				endpoint += 'businesses/'
			}

			if (to.params.state) {
				endpoint += `${to.params.state}/`
			}
			if (to.params.city) {
				endpoint += to.params.city
			}

			if (to.name === 'cityGuide') {
				endpoint = `guides/city/${to.params.city}`
				params.filters = null
				params.state = to.params.state || null
			}

			if (to.params.page && to.params.page !== undefined) {
				params.page = to.params.page
			}

			const getData = {
				endpoint: endpoint,
				params: params
			}

			if (to.name === 'cityIndex' || to.name === 'home' || to.name === 'cityGuide') {
				return // don't fetch REST data for city index
			}

			// make api call
			const response = await this.newGet(getData)

			if (response.data && response.data.success === true) {
				if (from && to.name === from.name && to.params.page > from.params.page) {
					this.NEXT_PAGE(response.data.results)
				} else {
					this.SET({ data: response.data.results, currentUrl: to.fullPath })
					if (from && to.params.page < from.params.page) {
						try {
							document.querySelector('#business-list').scrollTop = 0
						} catch (e) {
							logError(e)
						}
					}
				}
			}
			return response
		},
		scrubFilters ({ to }) {
			const dealsUrl = 'marijuana-dispensary-deals'
			const reg = /\/|\+/g
			let filters = to.fullPath && to.fullPath.substring(1).replace(reg, ',').split(',') // this will replace '+' and '/' with commas and remove leading slash
			const tempScrubber = [] // the filter scrubber removes any unwanted params from the filter string so we only pass the info we need to the api.
			const filterScrubber = tempScrubber.filter(e => e != null) // remove any nullish values (i.e. null and undefined)

			// remove page number from filters array that are not caught as page params due to 'DealsWithFilters' page generic regex
			this.pageNumber = parseInt(filters[filters.length - 1])
			if (isNaN(this.pageNumber)) {
				this.pageNumber = 1
			} else {
				filters.pop()
			}

			// false if no filters
			if (filters.length < 1) return false

			// use the scrubber
			tempScrubber.push(to.params.city, to.params.state, dealsUrl, String(to.params.page)) // add any params we want to get rid of.

			filters = filters.filter((filter) => {
				return filterScrubber.indexOf(filter) < 0
			})

			if (filters.length < 1) return false
			return filters.join(',')
		}
	},
	serverPrefetch() {
		this.$store.commit('setSsrPageLoad', this.$route.fullPath)
		return this.fetchData({ to: this.$route })
	}
}
</script>
