export const BLOG = 'News / Blog'
export const BRANDS = 'Brands'
export const CBD_STORES = 'CBD Stores'
export const DEALS = 'Deals'
export const DELIVERY = 'Delivery'
export const DISPENSARY = 'Dispensaries'
export const MEDICAL = 'Doctors'
export const PRODUCTS = 'Products'
export const STRAINS = 'Strains'

export default {
	BLOG,
	BRANDS,
	CBD_STORES,
	DEALS,
	DELIVERY,
	DISPENSARY,
	MEDICAL,
	STRAINS,
	PRODUCTS
}
